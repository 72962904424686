import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Right from "../../assests/home_right2.png";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import "./hero.css";
import { useEffect } from "react";

function TypingText({ text }) {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let currentText = "";
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      if (currentIndex <= text.length) {
        currentText = text.substring(0, currentIndex);
        setDisplayedText(currentText);
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 30); // Adjust the typing speed by changing the interval duration

    return () => clearInterval(intervalId);
  }, [text]);

  return <div className="typing-text" id="expertise">{displayedText}</div>;
}

function ControlledCarousel() {
  const services5 = [
    {
      name: "Design Services",
      url: "#",
    },
    {
      name: "CAE Services",
      url: "#",
    },
    {
      name: "Software Services",
      url: "#",
    },
    {
      name: "Altair Products",
      url: "#",
    },
    {
      name: "PTC Products",
      url: "#",
    },
  ];

  const [index, setIndex] = useState(0);
  const [currentService, setCurrentService] = useState(services5[0].name);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect22 = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the active index to show the next service name
      setActiveIndex((prevIndex) => (prevIndex + 1) % services5.length);
      setCurrentService(services5[activeIndex].name);
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [activeIndex]);



  const services = [
    {
      name: "Data Analytics & AI",
      url: "/altairproducts/",
      number: 1,
    },
    {
      name: "Electronic System Design",
      url: "/altairproducts/",
      number: 2,
    },
    {
      name: "Fluid & Thermal",
      url: "/altairproducts/",
      number: 3,
    },
    {
      name: "Structural Analysis.",
      url: "/altairproducts/",
      number: 4,
    },
    {
      name: "Multiphysics",
      url: "/altairproducts/",
      number: 5,
    },
    {
      name: "Manufacturing",
      url: "/altairproducts/",
      number: 6,
    },

    // {
    //   name: "Sim Solid",
    //   url: "/simulation/altair/simsolid",
    // },
    // {
    //   name: "Hyper Mesh",
    //   url: "/simulation/altair/hypermesh",
    // },
    // {
    //   name: "Opti Struct",
    //   url: "/simulation/altair/optistruct",
    // },

    // {
    //   name: "Service 1",
    //   url: "#",
    // },
    // {
    //   name: "Service 1",
    //   url: "#",
    // },
    // {
    //   name: "Service 1",
    //   url: "#",
    // },
  ];

  const services2 = [
    {
      name: "Creo",
      url: "/cad/creo",
      number: 1,
    },
    {
      name: "OnShape",
      url: "/cad/onshape",
      number: 2,
    },
    {
      name: "Windchill",
      url: "/plm/windchill",
      number: 3,
    },
    {
      name: "Vuforia",
      url: "/arvr/vuforia",
      number: 4,
    },
    {
      name: "ThingWorx",
      url: "/iot/thingworx",
      number: 5,
    },

    // {
    //   name: "Service 1",
    //   url: "#",
    // },
    // {
    //   name: "Service 1",
    //   url: "#",
    // },
    // {
    //   name: "Service 1",
    //   url: "#",
    // },
  ];
  const services3 = [
    {
      name: "Product Development",
      url: "#",
      number: 1,
    },
    {
      name: "Web and Mobile Development",
      url: "#",
      number: 2,
    },
    {
      name: "UI/UX Design",
      url: "#",
      number: 3,
    },
    {
      name: "Machine Learning & AI",
      url: "#",
      number: 4,
    },
    {
      name: "IoT Development",
      url: "#",
      number: 5,
    },
  ];

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} fade>
      <Carousel.Item>
        <div id="hero_home">
          <div className="hero">
            <div
              className="hero_cnt"
              data-aos="fade-up-right"
              data-aos-duration="800"
            >
              {/* <div className="hero_tag">Accelerating Innovation</div> */}
              {/* <div className="hero_line_header">
            <div className="hero_line"></div>
          </div> */}
              <div className="hero_1">
               Empowering Your Transition to Digital Arenas from Physical Realms.{" "}
              </div>
              <div className="typing-text" id="expertise">
              <TypingText text={currentService} />
              </div>
            </div>
            <div
              className="hero_img"
              data-aos="fade-up-left"
              data-aos-duration="800"
            >
              <img src={Right} alt="" />
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      {/* 4 */}
      <Carousel.Item>
        <div id="hero_home5">
          <div className="hero_altrCnt">
            <div className="altairProdH">Altair Products</div>
            <div className="altrProdCnt">
              Trusted by industry leaders, OptiStruct empowers engineers with
              robust simulation capabilities for efficient product development
              and performance assessment.
            </div>
          </div>
          <div className="services_altr " data-aos="fade-left">
            {/* <div className="srv_prvd">Top Altair Products we Handle </div> */}
            {/* <ListGroup>
              {services.map((service) => {
                return (
                  <ListGroupItem className="serviT">
                    <Link to={service.url} className="aiOutLineAlign">
                      {service.name}
                      <span className="hand"> <AiOutlineRight /></span>
                    </Link>
                  </ListGroupItem>
                );
              })}
            </ListGroup> */}
            <div className="services-container">
              {services.map((service, index) => (
                <div
                  className={`service-item ${
                    index === activeIndex ? "active" : ""
                  }`}
                  key={service.number}
                  onClick={() => handleSelect22(index)}
                >
                  <div
                    className={`lineend number-circle ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {service.number}
                  </div>
                  <Link
                    to={service.url}
                    className="service-content"
                    activeClassName="active"
                  >
                    {service.name}
                  </Link>
                  {index < services.length - 1 && (
                    <div
                      className={`line12 ${
                        index === activeIndex ? "active" : ""
                      }`}
                    ></div>
                  )}
                  {/* <div className={`lineend ${index === activeIndex ? "active" : ""}`}>
        {service.number}
        </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      {/* 4 */}
      <Carousel.Item>
        <div id="hero_home4">
          <div className="hero_altrCnt">
            <div className="altairProdH">PTC Products</div>
            <div className="altrProdCnt">
              PTC is a globally recognized company known for its expertise in
              delivering solutions in the fields of Product Lifecycle Management
              (PLM), Computer-Aided Design (CAD), Internet of Things (IoT), and
              Augmented Reality (AR).
            </div>
          </div>
          <div className="services_altr " data-aos="fade-left">
            {/* <div className="srv_prvd">Top PTC Products we Handle</div> */}
            {/* <ListGroup>
              {services2.map((service) => {
                return (
                  <ListGroupItem className="serviT">
                    <Link to={service.url} className="aiOutLineAlign">
                      {service.name}
                      <span className="hand"> <AiOutlineRight /></span>
                    </Link>
                  </ListGroupItem>
                );
              })}

            </ListGroup> */}
            <div className="services-container">
              {services2.map((service, index) => (
                <div
                  className={`service-item ${
                    index === activeIndex ? "active" : ""
                  }`}
                  key={service.number}
                  onClick={() => handleSelect22(index)}
                >
                  <div
                    className={`lineend number-circle ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {service.number}
                  </div>
                  <Link
                    to={service.url}
                    className="service-content"
                    activeClassName="active"
                  >
                    {service.name}
                  </Link>
                  {index < services.length - 2 && (
                    <div
                      className={`line12 ${
                        index === activeIndex ? "active" : ""
                      }`}
                    ></div>
                  )}
                  {/* <div className={`lineend ${index === activeIndex ? "active" : ""}`}>
        {service.number}
        </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      {/* <Carousel.Item>
  <div className="hero" id="hero_home4">
    <video autoPlay muted loop playsInline className="background-video">
    <source src="https://drive.google.com/uc?export=download&id=1mDvwi4tQ2hCqeuZeR9ig55c0jiq-h0vr" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="hero_altrCnt">
      <div className="altairProdH">Altair Products</div>
      <div className="altrProdCnt">
        Trusted by industry leaders, OptiStruct empowers engineers with robust simulation capabilities for efficient product development and performance assessment.
      </div>
    </div>
    <div className="services_altr " data-aos="fade-left">
            <ListGroup>
              {services.map((service) => {
                return (
                  <ListGroupItem className="serviT">
                    <Link to={service.url} className="aiOutLineAlign">
                      {service.name}
                      <span className="hand"> <AiOutlineRight /></span>
                    </Link>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
  </div>
      </Carousel.Item> */}

      {/* <Carousel.Item>
  <div className="hero" id="hero_home4">
    <video autoPlay muted loop playsInline className="background-video">
      <source src="https://drive.google.com/uc?export=download&id=1MpYRo7HWYl4mTBLIew-xf5nQ_mK-1cze" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="hero_altrCnt">
      <div className="altairProdH">PTC Products</div>
      <div className="altrProdCnt">
        PTC is a globally recognized company known for its expertise in delivering solutions in the fields of Product Lifecycle Management (PLM), Computer-Aided Design (CAD), Internet of Things (IoT), and Augmented Reality (AR).
      </div>
    </div>
    <div className="services_altr" data-aos="fade-left">
      <ListGroup>
        {services2.map((service) => (
          <ListGroupItem className="serviT" key={service.url}>
            <Link to={service.url} className="aiOutLineAlign">
              {service.name}
              <span className="hand">
                {" "}
                <AiOutlineRight />
              </span>
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  </div>
      </Carousel.Item> */}

      <Carousel.Item>
        <div id="hero_home3">
          <div className="hero_altrCnt">
            <div className="altairProdH">Software Services</div>
            <div className="altrProdCnt">
              We quickly transform your business ideas into more modern,
              automated, and enjoyable digital solutions like web portals and
              mobile apps, we provide you with the NextGen IT solutions to meet
              your business objectives and prevail in the face of intense
              competition.
            </div>
          </div>
          <div className="services_altr " data-aos="fade-left">
            {/* <div className="srv_prvd">Software Services we Handle</div> */}
            {/* <ListGroup>
              {services3.map((service) => {
                return (
                  <ListGroupItem className="serviT">
                    <Link to={service.url} className="aiOutLineAlign">
                      {service.name}
                      <span className="hand"> <AiOutlineRight /></span>
                    </Link>
                  </ListGroupItem>
                );
              })}
            </ListGroup> */}
            <div className="services-container">
              {services3.map((service, index) => (
                <div
                  className={`service-item ${
                    index === activeIndex ? "active" : ""
                  }`}
                  key={service.number}
                  onClick={() => handleSelect22(index)}
                >
                  <div
                    className={`lineend number-circle ${
                      index === activeIndex ? "active" : ""
                    }`}
                  >
                    {service.number}
                  </div>
                  <Link
                    to={service.url}
                    className="service-content"
                    activeClassName="active"
                  >
                    {service.name}
                  </Link>
                  {index < services.length - 2 && (
                    <div
                      className={`line12 ${
                        index === activeIndex ? "active" : ""
                      }`}
                    ></div>
                  )}
                  {/* <div className={`lineend ${index === activeIndex ? "active" : ""}`}>
        {service.number}
        </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
