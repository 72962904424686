import React from "react";
import "./onshapefeatures.css";
import Accordion from 'react-bootstrap/Accordion';
import { useState } from "react";

export default function OnShapeFeatures({ features, id, className, title }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div id={id} style={{ backgroundColor: "#f8fafc" }}>
      <div className="onshape_fea_headerV2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >{title}

<div className={`lines-wil ${isHovered ? "hovered" : ""}`}></div>
      </div>
      <div className={`onshape_featuresV2 container ${className}`}>
        <Accordion defaultActiveKey="0">
          {features.map((feature, index) => {
            return (
              <Accordion.Item eventKey={index}>
                <Accordion.Header className="accordHead">{feature.name}</Accordion.Header>
                <Accordion.Body>
                  {feature.content}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>

      </div>
    </div>
  );
}
