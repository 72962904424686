import React from "react";
import "./services.css";
import Info from "../../components/HomeCompP2/homeComp";
import Top from "../../assests/Home/Top.svg";
import SecRight from "../../assests/Home/LeftOne.svg";
import ThirdRight from "../../assests/Home/RightOne.svg";
import BtmOne from "../../assests/Home/BottomOne.svg";
import homeBg from "../../assests/homebg_scnd.svg";
import CA from '../../assests/CaServices.jpg'

import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

function Services() {
  const features = [
    {
      name: "Design Services",
      content:
        "We encompass a broad spectrum of offerings, aiding individuals and businesses in crafting and developing diverse products, problem-solving skills, and technical expertise. Our mission is to empower innovation, improving products, and drive success across a multitude of industries.",
      reversed: true,
      img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698493370/Images_Website/efn1b1k2cahq2rjrhei2.svg",
      designImg: SecRight,
      gotoUrl: "designservices",
    },
    {
      name: "CAE Services",
      content:
        "We specialize in leveraging cutting-edge FEA, Computational tools and methodologies to simulate, analyze, and optimize mechanical systems, ensuring robust and efficient designs across diverse industries. Our CAE Services can elevate your product development and design processes.",
      reversed: false,
      img: "https://altair.com/images/default-source/content-images/applications_fluids-thermal_externalaero_3-column-card_image-jpg.jpg?sfvrsn=4596d4bb_0",
      designImg: ThirdRight,
      gotoUrl: 'caeservices'
    },
    {
      name: "PLM Services",
      content:
        "PLM (Product Lifecycle Management) services streamline product development, enhance collaboration, ensure data accuracy, and boost quality, covering the entire product journey from concept to retirement, crucial for modern businesses success.",
      reversed: true,
      img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698494099/Images_Website/ygsk10rkolpk9ju753wo.svg",
      designImg: BtmOne,
      gotoUrl: 'windchill'

    },
    {
      name: "Software Services",
      content:
        "We specialize in creating captivating and interactive experiences that bridge the gap between the physical and digital worlds. Our cutting-edge solutions harness the power of emerging technologies, redefining engagement, education, and customer interaction to inspire lasting connections.",
      reversed: false,
      img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698494173/Images_Website/babwuvbewqvxtp9ucyra.svg",
      designImg: BtmOne,
      gotoUrl: "Sofrware",
    },
  ];

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div id="services" style={{ backgroundImage: homeBg,overflow:'hidden' }}>
      <div className="">
        <div className="dfgss" data-aos="fade-down" data-aos-duration="800">
          <img className="hmimg1" src={Top} alt="" srcset="" />
          {/* <div className="services_heading">
            We offer the following Services and Products
          </div> */}

          {/* <div id="serpdtstxt">
            <span id="gghh">
              Discover a wide range of tailored solutions to meet your needs
            </span>
          </div> */}
          <div className="whats_new_header" style={{paddingTop:80}}>
            <div className="header_new2"  
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            Our Services
            <div className={`line ${isHovered ? "hovered" : ""}`}></div>
            </div>
            
          </div>
        </div>
        <Info features={features} id="" />
      </div>
    </div>
  );
}

export default Services;
