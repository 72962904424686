import React from "react";
import Home from "../../components/Home_pdts/home";
import Logo from "../../assests/codebemer_logo.png";
import Img1 from "../../assests/codebeamer/img1.webp";
import ScrolltoTop from "react-scroll-to-top";

import Info from "../../components/Products_Information/info";
import ShowMoreText from "react-show-more-text";
import Overview from "../../components/Overview/overview";

import "./codebeamer.css";

export default function Codebeamer() {
  const home_content = {
    header: " Codebeamer / ALM Solution",
    content:
      "Codebeamer is an Application Lifecycle Management (ALM) platform for modern product and software development. See how Codebeamer can help your organization to simplify complex product and software engineering at scale.",
    logo: Logo,
    // classHome: "windchill",
    back_img:
      "https://res.cloudinary.com/dbomu1erj/image/upload/v1699867780/Images_Website/Products/s4tevvhvnobeucndbxwc.png",
  };

  const explore = [
    {
      name: "Explore the benefits of Codebeamer",
      content:
        "Codebeamer is an innovative digital workflow solution that enhances collaboration in development, improves product line efficiency, and ensures regulatory compliance. It extends beyond traditional Application Lifecycle Management (ALM) with configurable product line capabilities and adaptability for complex processes. By connecting development tools seamlessly, it offers a unified platform and automates process control for regulatory standards. Trusted by leading product developers, Codebeamer is scalable, reliable, and customizable, making it an excellent choice for development teams.",
      reversed: true,
      img: Img1,
    },
  ];

  const overViewContent = {
    header: "Explore the benefits of Codebeamer",
    content:
      "For complex products, manufacturing, construction machinery and transport vehicles it is crucial to be able to deliver top quality service, as well as considering the potential profitability of spare part sales. This puts high demand on maintenance in terms of diagnosis as well as correct, complete and easily accessible product information. It also stresses the need for a process approach.",
    video: "https://www.youtube-nocookie.com/embed/zB9vn2anJrw?controls=0",
    id: "introduction_windchill",
  };
  const features = [
    {
      name: "Requirements Management",
      content:
        "Transform market insights into high-quality products at unprecedented speed. Achieve clarity and alignment across all stakeholders to minimize costs and accelerate delivery. Monitor objects and actions throughout the entire delivery process.",
      reversed: false,
      img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1699867832/Images_Website/Products/ucuxup3wddy8arirunj1.png",
    },
    {
      name: "Quality Assurance & Testing",
      content:
        "Incorporate quality management into your development processes, building trust in your products. Effectively monitor and manage QA activities across the entire lifecycle, accelerating product verification.",
      reversed: true,
      img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1699867867/Images_Website/Products/hwzqzyf4rllvkix1bq6o.png",
    },
  ];

  return (
    <div style={{ backgroundColor: '#f8fafc' }}>
      <Home {...home_content} />
      <ScrolltoTop />
      {/* <Info title="About Codebeamer" features={explore} id="codebeamer_about" /> */}
      <Overview {...overViewContent} />
      <div className="scndcnt container">
        <div className="whatSaaS">Codebeamer features</div>
        <div className="lines_pls">
          <div className="lines"></div>
        </div>
        <div className="whatSaaS_ans">
          <ShowMoreText
            /* Default options */
            lines={3}
            more="Expand"
            less="Show less"
            className="content-css"
            anchorClass="show-more-less-clickable"
            expanded={false}
            // width={2000}
            truncatedEndingComponent={"..... "}
          >
            Codebeamer is a complete lifecycle management solution that excels
            in requirements, risk, and test management. It helps teams capture
            and track requirements, manage risks, and plan and execute tests
            efficiently, ensuring a smooth development process.
          </ShowMoreText>
        </div>
      </div>
      <Info
        // title="About Codebeamer"
        features={features}
        id="codebeamer_about"
      />
      {/* <OnShapeFeatures features={onshapeFeatures} id="features" /> */}
      {/* <Info features={framework} id="framework_onshape" /> */}
    </div>
  );
}
