import React from "react";
import "./about.css";
import People from "../../assests/people.jpg";
import { Last } from "react-bootstrap/esm/PageItem";
import { ListGroupItem } from "react-bootstrap";
import vision from "../../assests/vision.jpg";
import { useState } from "react";
import Info from "../../components/HomeCompP2/homeComp";
import { useEffect } from "react";


const features = [
  {
    name: "OUR VISION",
    content:
      "Our vision is to simplify complexity in design with technological intelligence and innovation. ",
    reversed: true,
    img:vision,
  },
  {
    name: "OUR MISSION",
    content:
      "Our mission is to help organizations build products that their consumers are looking forward to.",
    reversed: false,
    img:vision,
  },
];

function About() {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  return (
    <div style={{overflow:'hidden'}}>
      {/* <div className="home_body" id="about">
        //container2//
        <div className="about_img">
        <img src={People} alt="" srcset="" />
      </div>

      //container1//
      <div className="about_data">
        <div className="boost">WHO WE ARE AND WHAT WE OFFER</div>
        <div className="about_text">
        <p>At Triyas we create befitting solutions for advanced technology products with technological prowess. We make organizations explore design innovation and build game-changing products with great quality and less time to market.</p>

        <p>Our team of experts deliver uncompromising quality, timely design & engineering solutions, with out-of-the-box approach & strategic thinking. We help our clients design products that are radical breakthroughs. We aim to leverage latest technology trends with in-house excellence and streamlining processes.
          voluptates dolore!</p>

         <p> We strongly believe in building meaningful relationships with clients by providing them value.</p>

          <p>We are a team of technology, design, and business enthusiasts with over 20 years of combined industry experience. We've been delivering remarkable tech solutions along with an exceptional business value to ensure client satisfaction. We constantly aim to bring creativity, precision and passion in everything we take up</p>
        </div>
      </div>
    </div> */}
      <div className="border111">
        <div className="listitems">
          <div
          data-aos="fade-right"
          data-aos-duration="800"
          >
            <div className="img">
              <img src={People} alt="" srcset="" />
            </div>
          </div>
          <div 
          className="textwidth"
          data-aos="fade-left"
          data-aos-duration="800"
          >
            <div 
            className="header11"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
              WHO WE ARE AND WHAT WE OFFER
              <div className={`lines-ab ${isHovered ? "hovered" : ""}`}></div>
            </div>
            <p>
              At Triyas we create befitting solutions for advanced technology
              products with technological prowess. We make organizations explore
              design innovation and build game-changing products with great
              quality and less time to market.
            </p>
            <p>
              Our team of experts deliver uncompromising quality, timely design
              & engineering solutions, with out-of-the-box approach & strategic
              thinking. We help our clients design products that are radical
              breakthroughs. We aim to leverage latest technology trends with
              in-house excellence and streamlining processes.
            </p>
            <p>
              We strongly believe in building meaningful relationships with
              clients by providing them value.
            </p>
            <p>
              We are a team of technology, design, and business enthusiasts with
              over 20 years of combined industry experience. We've been
              delivering remarkable tech solutions along with an exceptional
              business value to ensure client satisfaction. We constantly aim to
              bring creativity, precision and passion in everything we take up
            </p>
          </div>
        </div>
      </div>

      <div className="holeborder">
        <div className="border11">
          <div 
          className="header"
          onMouseEnter={() => setIsHovered1(true)}
          onMouseLeave={() => setIsHovered1(false)}
          >
            WHY TRIYAS
            <div className={`lines1-ab ${isHovered1 ? "hovered" : ""}`}></div>
          </div>
          <div className="listitems">
            <div
            data-aos="fade-right"
            data-aos-duration="800"
            >
              <ul className="hearerwhy" >
                <li>Leading in CAD/CAE technology</li>
              </ul>
              <ul className="about_text" style={{ listStyleType: "none" }}>
                <li>Turn-key solution provider</li>
                <li>Technology management embedded in corporate strategy</li>
                <li>Comprehensive capabilities</li>
                <li>Thorough knowledge of development and production</li>
                <li>Strategic partnerships.</li>
              </ul>
            </div>
            <div
            data-aos="fade-left"
            data-aos-duration="800"
            >
              <ul className="hearerwhy" >
                <li> Provide flexible work force</li>
              </ul>
              <ul className="about_text"  style={{ listStyleType: "none" }}>
                <li>On site and offshore expandable capacity</li>
                <li>Flexible pool of experienced consultants</li>
                <li>Extensive project and product management experience</li>
                <li>State-of-the-art software and hardware.</li>
                {/* <li>Strategic partnerships.</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Info features={features} id="benifits_windchill" />
      {/* <div className="border111">
        <div className="listitems">
          <div
          data-aos="fade-right"
          data-aos-duration="800"
          >
            <div className="img">
              <img src={vision} alt="" srcset="" />
            </div>
          </div>
          <div
          className="textwi"
          data-aos="fade-left"
          data-aos-duration="800"
          >
            <div 
            className="header11"
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            >
              OUR VISION
            <div className={`lines2-ab ${isHovered2 ? "hovered" : ""}`}></div>
            </div>
            <div className="">
            Our vision is to simplify complexity in design with technological
            intelligence and innovation.
            </div>
          </div>
        </div>
      </div>

      <div className="border111">
        <div className="listitems">
          <div 
          className="textwi"
          data-aos="fade-right"
          data-aos-duration="800">
          <div 
            className="header111"
            onMouseEnter={() => setIsHovered3(true)}
            onMouseLeave={() => setIsHovered3(false)}
            >
              OUR MISSION
              <div className={`lines3-ab ${isHovered3 ? "hovered" : ""}`}></div>
            </div>
            <div className="textalign">
            Our mission is to help organizations build products that their
            consumers are looking forward to.
            </div>
          </div>
          <div
          data-aos="fade-left"
          data-aos-duration="800"
          >
            <div className="img">
              <img src={vision} alt="" srcset="" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About;
