import React from "react";
import "./about.css";
import People from "../../assests/altair_bg2.png";
import Satisfaction from '../../assests/satisfaction.svg'
import Years from '../../assests/YearsExicellence.svg'


// function AboutAltair() {
export default function AboutAltair({ header, content,year,year2,title,title2,img,img1,img2 }) {
  return (
    <div className="home_body" id="about_altair">
      <div className="about_img altair_abt_img">
        <img src={img} alt="" srcset="" />
      </div>
      <div className="about_data altair_abt_data">
        {/* <div className="about_header">ABOUT US</div> */}
        <div className="boost1">{header}</div>
        <div className="about_text">
        {content}
        </div>
        <div className="data_details">
          <div className="years_header">
            <div className="satisfac">
              <img src={img1} alt="" />
              <span className="years">{year}</span>
            </div>
            <span className="year_text">{title}</span>
          </div>
          <div className="years_header">
            <div className="satisfac">
              <img src={img2} alt="" />
              <span className="years">{year2}</span>
            </div>
            <span className="clients_text">{title2}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
