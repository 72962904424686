import React, { useState } from "react";
import "./altair.css";
import Home from "../../components/AltairHome/home";
import Nav from "../../components/AltairNav/nav";
import Pdts from "../../components/Altairpdts/pdts";
import AboutAltair from "../../components/About Altair/about";
import ProductsNav from "../../components/prodyctsNav/productsnav";
import ScrollToTop from "react-scroll-to-top";
import People from "../../assests/altair_bg2.png";
import Satisfaction from '../../assests/satisfaction.svg'
import Years from '../../assests/YearsExicellence.svg'

export default function Altair() {
  const [search, setSearch] = useState("");
  const [isSearched, setisSearched] = useState(false);

  const handleSearchText = (text) => {
    // setSearch(e.target.value)
    console.log(typeof text);
    if (typeof text === "string") {
      if (text === "") {
        console.log("Cleared Filters");
        setSearch("");
        setisSearched(false);
      } else {
        setSearch(text);
        setisSearched(true);
      }
    }
  };

  const naveles = [
    {
      name: "Introduction",
      id: "introduction_onshape",
    },
    {
      name: "Platform",
      id: "platform_onshape",
    },
    {
      name: "Features & Functionality",
      id: "features",
    },
    {
      name: "Framework",
      id: "framework_onshape",
    },
  ];
  const aboutService = {
    header:
      "We are the Channel Partners of Altair",
    content:
      "We are proud to be a dedicated channel partner for Altair, a global leader in engineering and product design solutions. Our partnership with Altair enables us to deliver cutting-edge technology and expertise to empower different organisations.",
    year:'5+',
    year2:'25+',
    title:'Years of Excellence ',
    title2:"Client's Satisfaction",
    img: People,
    img1:Satisfaction,
    img2:Years,
  }
  return (
    <div>
      <Home />
      {/* <ProductsNav naveles={naveles} logo={Logo} /> */}
      <ScrollToTop />
      <AboutAltair {...aboutService}/>
      <Nav handleSerachText={handleSearchText} search={search} />
      <Pdts search={search} isSearched={isSearched} />
    </div>
  );
}
