import React from "react";
// import "./creo.css";
import CreoLogo from "../../assests/cero_logo2.webp";
import { HashLink } from "react-router-hash-link";
import I3 from "../../assests/creoI3.svg";
import I1 from "../../assests/creoI1.svg";
import I2 from "../../assests/creoI2.svg";
import ITO from "../../assests/ITO.jpg"
import AI from "../../assests/AI.jpg"
import UIUX from "../../assests/UIUX.jpg"
import WebMobile from "../../assests/WebMobile.jpg"
import Product from "../../assests/Product.jpg"
import CreoPkg from "../../assests/creo_packages.webp";
import TableCreo from "../../components/creoTable/tablecreo";
import Creonew from "../../components/creonew/creonew";
import CreoLogoNav from "../../assests/creo_logo.png";
import ProductsNav from "../../components/prodyctsNav/productsnav";
import ScrolltoTop from "react-scroll-to-top";
import Modal from "../../components/Modal/Modal";
import engine from "../../assests/engine.svg"
import { useState } from "react";
import Info from "../../components/HomeCompP2/homeComp";

export default function Sofrware() {
  const benifits_info = `PTC Creo Parametric is a comprehensive 3D CAD solution that provides engineers with a seamless journey from concept to product validation. With a rich set of powerful features, engineers can precisely simulate and optimize their designs, taking into account factors such as weight, performance, cost, and sustainability. The software accommodates a wide range of materials and manufacturing processes. Furthermore, it goes beyond traditional engineering practices by replacing 2D drawings with fully-annotated 3D models, simplifying the manufacturing, inspection, and servicing of parts and assemblies.`;

  const creobenifits = [
    {
      name: "Lower cost",
      img: I1,
      disc: "Our CAD tools provide several benefits for cost savings, including reducing material, labor, and overhead expenses. Take advantage of our part optimization tools to minimize material costs and streamline assembly processes.",
    },
    {
      name: "Performance",
      img: I2,
      disc: "Set your products apart with outstanding performance. Optimize your designs for weight, reliability, and sustainability, while harnessing the full potential of cutting-edge additive and composite manufacturing processes.",
    },
    {
      name: "Time to Market",
      img: I3,
      disc: "PTC Creo accelerates product development. Ensure design quality from the start to prevent costly redesigns and rework. Streamline downstream processes with annotated 3D models.",
    },
  ];

  const naveles = [
    {
      name: "Benefits of Creo",
      id: "creo_benifits",
    },
    {
      name: "What's New",
      id: "whats_new_creo",
    },
    {
      name: "Packages",
      id: "packages",
    },
    {
      name: "Pricing",
      id: "pricing",
    },
  ];

  const features = [
    {
      name: "IOT Development",
      content:
        "We lead the charge in the dynamic world of Internet of Things (IoT) development, crafting innovative solutions that bridge the gap between the physical and digital realms. Our expertise lies in creating smart, interconnected ecosystems that empower businesses and individuals to leverage the power of data-driven decision-making and automation.",
      reversed: true,
      img:ITO
      // img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698493370/Images_Website/efn1b1k2cahq2rjrhei2.svg",
      // designImg: SecRight,
      // gotoUrl: "designservices",
    },
    {
      name: "Machine Learning & AI",
      content:
        "We are a team of passionate data scientists, machine learning engineers, and AI experts dedicated to delivering cutting-edge solutions tailored to meet your specific needs. Our deep expertise, coupled with a commitment to innovation, allows us to create transformative AI-driven applications.",
      reversed: false,
      img:AI
      // img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698493370/Images_Website/efn1b1k2cahq2rjrhei2.svg",
      // designImg: ThirdRight,
      // gotoUrl: 'caeservices'
    },
    {
      name: "UI/UX Design",
      content:
        "We specialize in creating captivating user experiences that seamlessly blend functionality with aesthetics. Our dedicated team of UI/UX designers combines creativity, empathy, and technical expertise to deliver designs that resonate with your audience and elevate your brand.",
      reversed: true,
      img:UIUX
      // img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698494099/Images_Website/ygsk10rkolpk9ju753wo.svg",
      // designImg: BtmOne,
      // gotoUrl: 'windchill'

    },
    {
      name: "Web & Mobile Development",
      content:
        "We excel in crafting robust, scalable, and user-centric web and mobile applications that drive business growth and enhance user experiences. With a dedicated team of developers and designers, we leverage cutting-edge technologies to bring your ideas to life.",
      reversed: false,
      img:WebMobile
      // img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698494173/Images_Website/babwuvbewqvxtp9ucyra.svg",
      // designImg: BtmOne,
      // gotoUrl: "Sofrware",
    },
    {
      name: "Product Development",
      content:
        "We specialize in turning ideas into impactful, market-ready software products. With a dedicated team of talented engineers, designers, and strategists, we empower businesses to innovate, disrupt industries, and deliver solutions that resonate with users.",
      reversed: true,
      img:Product
      // img: "https://res.cloudinary.com/dbomu1erj/image/upload/v1698494173/Images_Website/babwuvbewqvxtp9ucyra.svg",
      // designImg: BtmOne,
      // gotoUrl: "Sofrware",
    },
  ];


  const [isQuoteModalOpen, setQuoteModalOpen] =useState(false);

  const handleQuoteModalOpen = () => {
    setQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setQuoteModalOpen(false);
  };

  return (
    <div className="creo_page" style={{overflow:'hidden'}}>
      <div className="first_cnt">
        <div className="glassefct">
        <div
            className="creo_info "
            data-aos="fade-up-right"
            data-aos-duration="800"
          >
          {/* <div className="creo_info"> */}
            <div className="creo_header">
              {/* <div className="creo_img_block">
                <img
                  src={CreoLogo}
                  className="creo_scndimg"
                  alt="Creo Logo"
                  srcset=""
                />
              </div> */}
              <div className="creo_header1">Software Services</div>
            </div>
            <div className="creo_gen_info1">
            Our software services stand at the forefront of technological innovation, offering a diverse range of solutions tailored to meet your unique needs. From custom software development to system integration, our dedicated team ensures that your business operates with efficiency and agility.
            </div>
            <div >
              <button className="contactusCreo1" onClick={handleQuoteModalOpen}>
                  Get an Estimated Quote
              </button>
            </div>
            <Modal open={isQuoteModalOpen} handleModalClose={handleQuoteModalClose} handleModelSubmit={Modal} />
          </div>
          {/* <div className="creo_img">
            <img src={CreoLogo} loading="lazy" alt="Creo Logo" srcset="" />
          </div> */}
        {/* </div> */}
        </div>
      </div>
      <ScrolltoTop />
      <Info features={features} id="" />
    </div>
  );
}
