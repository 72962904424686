import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./contact.css";
import emailjs from "@emailjs/browser";
import Add1 from "../../assests/Home/add1.svg";
import Add2 from "../../assests/Home/add2.svg";
import Add3 from "../../assests/Home/add3.svg";

const Contact = ({ theme }) => {
  const form = useRef();
  const [details, setDetails] = useState({
    name: "",
    email: "",
    company: "",
    phno: "",
    jobTitle: "",
    officeAddress: "",
    productService: "",
    message: "",
  });

  const [mailSent, setMailSent] = useState(true);

  const handleChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setDetails({ ...details, [inputName]: inputValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      details.name === "" ||
      details.email === "" ||
      details.company === "" ||
      details.company === "" ||
      details.phno === ""
    ) {
      toast.error("Fill all the Required fields");
    } else if (details.email.indexOf("@") === -1) {
      toast.warning("Enter a valid email");
    } else if (details.phno.length !== 10) {
      toast.warning("Enter a valid Mobile Number");
    } else {
      setMailSent(false);
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE,
          process.env.REACT_APP_TEMPLATE_ID,
          e.target,
          process.env.REACT_APP_USER_ID
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              "Thanks for contacting me! You will receive a reply soon."
            );
            setMailSent(true);
            setDetails({
              name: "",
              email: "",
              phno: "",
              company: "",
              jobTitle: "",
              officeAddress: "",
              productService: "",
              message: "",
            });
          }
        })
        .catch((err) => {
          toast.error(err.message);
          setMailSent(true);
        });
    }
  };

  return (
    <div className="contactPage" id="contact">
      <div className="containerContact container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="contactForm">
          <div className="cmpyAddress">
            <div className="contacthead">
            <div className="contactborder">
            <div className="contactus">Connect With Us!</div>
            <div className="textus">Do you have any inquiries or thoughts you'd like to share? Please let us know how we can assist you or address.</div>
            </div>
            <div className="contactimg-container" >
              <img src='https://res.cloudinary.com/dpt69vgvg/image/upload/v1704696776/MicrosoftTeams-image_5_ahroua.jpg' className="contactimg"/>
            </div>
            </div>
            <div className="compyCnt">
              <div id="contactHeader">Connect With Us!</div>
              <form
                data-aos="fade-up"
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="contactdetails">
                  <div className="inputLabel">
                    <input
                      type="text"
                      className={`form-control ${
                        details.name === "" ? "is-invalid" : ""
                      }`}
                      placeholder="Name"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={details.name}
                      required
                    />
                    <div className="invalid-feedback">Name is required</div>
                  </div>
                  <div className="inputLabel">
                    <input
                      type="email"
                      className={`form-control ${
                        details.email === "" ? "is-invalid" : ""
                      }`}
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={details.email}
                      required
                    />
                    <div className="invalid-feedback">
                      Enter a valid email
                    </div>
                  </div>
                </div>
                <div className="contactdetails">
                  <div className="inputLabel">
                    <input
                      type="text"
                      className={`form-control ${
                        details.phno === "" ? "is-invalid" : ""
                      }`}
                      placeholder="Phone Number"
                      id="phno"
                      name="phno"
                      onChange={handleChange}
                      value={details.phno}
                      required
                    />
                    <div className="invalid-feedback">
                      Enter a valid Phone Number
                    </div>
                  </div>
                  <div className="inputLabel">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company"
                      id="company"
                      name="company"
                      onChange={handleChange}
                      value={details.company}
                    />
                  </div>
                </div>
                <div className="contactdetails">
                  <div className="inputLabel">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job Title"
                      id="jobTitle"
                      name="jobTitle"
                      onChange={handleChange}
                      value={details.jobTitle}
                    />
                  </div>
                  <div className="inputLabel">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Office Address"
                      id="officeAddress"
                      name="officeAddress"
                      onChange={handleChange}
                      value={details.officeAddress}
                    />
                  </div>
                </div>
                <div className="contactdetails">
                  <div className="inputLabel">
                    <select
                      className={`form-select ${
                        details.productService === "" ? "is-invalid" : ""
                      }`}
                      id="productService"
                      name="productService"
                      onChange={handleChange}
                      value={details.productService}
                      required
                    >
                      <option value="" disabled>
                        Select Products/Service
                      </option>
                      <option value="Product1">PTC Products</option>
                      <option value="Product2">Altair Products</option>
                      <option value="Product2">Design Services</option>
                      <option value="Product2">CAE Services</option>
                      <option value="Product2">Software Services</option>
                      {/* Add more options as needed */}
                    </select>
                    <div className="invalid-feedback">
                      Select a Product/Service
                    </div>
                  </div>
                </div>
                <div className="message">
                  <div className="inputLabel">
                    <textarea
                      className={`form-control ${
                        details.message === "" ? "is-invalid" : ""
                      }`}
                      placeholder="Message"
                      id="message"
                      name="message"
                      onChange={handleChange}
                      rows={5}
                      value={details.message}
                    />
                  </div>
                </div>
                <div className="submitbtn">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!mailSent}
                  >
                    {mailSent ? "Submit" : "Sending..."}
                  </button>
                </div>
              </form>
            </div>
            <div className="cmpydtls">
              <div className="ourAddress">Our Address</div>
              <div className="dfvg">
                <div className="cmpadd">
                  <div className="cntimgs">
                    <img src={Add1} alt="" />
                  </div>
                  <div className="cmpydtt">
                    Plot Number #682 Babukhan Rasheed Plaza Road #36, Jubilee
                    Hills, Hyderabad 500033 India
                  </div>
                </div>
                <div className="cmpadd cmpadd2">
                  <div className="cntimgs">
                    <img src={Add2} alt="" />
                  </div>
                  <div className="cmpydtt">+919704477677</div>
                </div>
                <div className="cmpadd cmpadd2">
                  <div className="cntimgs">
                    <img src={Add3} alt="" />
                  </div>
                  <div className="cmpydtt">info@triyas.com</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
